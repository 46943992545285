<script setup lang="ts">
  import Stars from "~/components/Stars.vue";

  const props = defineProps<{
    avatar: string;
    name: string;
    rating: number | string;
    message: string;
    link: string;
    type: string;
  }>();

  let imgPath = '/google.jpg';
  let imgAlt = 'Google';

  if (props.type === 'product') {
    imgPath = '/product-review.png';
    imgAlt = 'Product';
  }
</script>

<template>
  <div class="col-12 col-md-6 col-xl-4 mb-3 px-md-3 px-2-larger d-flex align-items-stretch h-auto">
    <div class="mt-5 card card-body h-auto w-100 border-0 client-review with-hover py-5">
      <div class="avatar mx-auto text-center mb-3">
        <NuxtImg format="webp"
                 quality="80"
                 height="120" width="120"
                 :src="props.avatar"
                 class="rounded-circle "
                 :alt="props.name" loading="lazy"/>
      </div>
      <div class="position-absolute review-platform">
        <NuxtImg format="webp"
                 quality="80"
                 height="40" width="40"
                 :src="imgPath" class="rounded-circle review-icon"
                 :alt="imgAlt" loading="lazy"/>
      </div>
      <h3 class="mb-3 text-center">{{props.name}}</h3>
      <Stars :rating="props.rating.toString()"/>
      <p class="text-center mb-4 review-msg">{{props.message}}</p>
      <a class="d-flex flex-row align-items-center justify-content-center text-uppercase mt-auto hover-head-shake animated infinite"
         aria-label="read review"
         :href="props.link">
        Read Review
        <NuxtImg class="link-img-arrow ms-2"
                 :alt="`Read More - ${props.name}`"
                 height="30" width="30"
                 src="/arrow.svg"
                 loading="lazy"/>
      </a>
    </div>
  </div>
</template>
