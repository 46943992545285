<script setup lang="ts">
  import {default as ReviewCard} from "~/components/Reviews/Card.vue";
  import {useAcfOption} from "~/composables/ApiHelper";
  import 'slick-carousel/slick/slick.min.js';
  import $ from "jquery";

  const clientReviews = await useAcfOption('client_reviews');
  const clientReviewLinks = await useAcfOption('client_review_links');

  onMounted(() => {
    $('#client-reviews-slider').slick({
      slidesToScroll: 1,
      slidesToShow: 3,
      autoplay: true,
      arrows: true,
      prevArrow: $('#btn-slide-prev'),
      nextArrow: $('#btn-slide-next'),
      responsive: [
        {
          breakpoint: 1199.98,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 991.98,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 575.98,
          settings: {
            slidesToShow: 1,
          }
        },

      ]
    });
  });
</script>

<template>
  <div class="w-100 my-5 bg-white py-4">
    <div class="container-xl categories">
      <div class="title mt-md-5 mb-5 d-md-flex justify-content-between align-items-center">
        <div class="col-12 col-md-6">
          <h2 class="mb-3 mb-md-0">Client Reviews</h2>
        </div>
        <div class="col-12 col-md-6 d-none d-md-block">
          <div class="row d-flex justify-content-end">
            <div class="col-6 col-md-auto pe-1 pe-md-0">
              <a :href="clientReviewLinks?.google_review_link || '/'"
                 class="btn btn-carousel-filter btn-outline-primary w-100 px-2 px-md-3">Google Reviews</a>
            </div>
            <div class="col-6 col-md-auto ps-1 ps-md-3">
              <a :href="clientReviewLinks?.product_review_link || '/'"
                  class="btn btn-carousel-filter btn-outline-primary w-100 px-2 px-md-3">Product Reviews</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-xl px-0 position-relative d-flex overflow-hidden">
      <div class="d-flex position-relative w-100">
        <div id="client-reviews-slider" class="w-100 d-flex align-items-stretch">
          <template v-for="clientReview in clientReviews">
            <ReviewCard :type="clientReview.type"
                        :link="clientReview.link"
                        :name="clientReview.name"
                        :rating="clientReview.rating"
                        :avatar="clientReview.avatar.url"
                        :message="clientReview.message"/>
          </template>
        </div>
        <div class="position-absolute top-50 start-0 translate-middle-y ps-3 d-md-block d-none" id="btn-slide-prev-container">
          <button class="btn btn-sm btn-link btn-slider-arrow" id="btn-slide-prev">
            <NuxtImg src="/arrow.svg" height="20" width="30"
                     alt="Previous Slide" class="link-img-arrow link-img-arrow-flip"/>
          </button>
        </div>
        <div class="position-absolute top-50 end-0 translate-middle-y pe-3 d-md-block d-none" id="btn-slide-next-container">
          <button class="btn btn-sm btn-link btn-slider-arrow" id="btn-slide-next">
            <NuxtImg src="/arrow.svg" height="20" width="30"
                     alt="Next Slide" class="link-img-arrow"/>
          </button>
        </div>
      </div>
    </div>
    <div class="container-xl d-flex justify-content-end d-md-none reviews-mobile-btns flex-column mt-3">
      <div>
        <a :href="clientReviewLinks?.google_review_link || '/'" class="btn btn-carousel-filter btn-outline-primary w-100 px-2 px-md-3">Google Reviews</a>
      </div>
      <div>
        <a :href="clientReviewLinks?.product_review_link || '/'" class="btn btn-carousel-filter btn-outline-primary w-100 px-2 px-md-3">Product Reviews</a>
      </div>
    </div>
  </div>
</template>
