<script setup lang="ts">
  import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

  const props = defineProps<{rating: string}>();
  const rating = parseInt(props.rating);

</script>

<template>
  <ul class="stars d-block text-center px-0">
    <li class="d-inline-block" v-for="v in rating" :key="v">
      <FontAwesomeIcon :icon="['fas', 'star']" class="mx-1"/>
    </li>
  </ul>
</template>
